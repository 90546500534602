import { LoaderFunction, MetaFunction, SerializeFrom } from '@remix-run/cloudflare';
import { ServerRuntimeMetaArgs } from '@remix-run/server-runtime';

export function remix_title_merge<T extends LoaderFunction>(
  fn: ((data: SerializeFrom<T>) => string) | string,
): MetaFunction<T> {
  return ({ data, matches }: ServerRuntimeMetaArgs<T>) => {
    const parentMeta = matches.flatMap((match) => match.meta ?? []);
    const parentTitle = parentMeta
      .filter((meta): meta is { title: string } => 'title' in meta)
      .map((meta) => meta.title)
      .pop();

    const title = typeof fn === 'string' ? fn : data ? fn(data as SerializeFrom<T>) : '';

    return [
      ...parentMeta.filter((meta) => !('title' in meta)),
      { title: parentTitle ? `${title} | ${parentTitle}` : title },
    ];
  };
}
